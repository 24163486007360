import { Component, render, h } from 'preact'
import Hadouken from 'url:./hadouken.mp3';

class App extends Component {
	START_TEXT = 'START'
	STOP_TEXT = 'STOP'

	AUDIO = new Audio(Hadouken)

	state = {
		buttonText: this.START_TEXT,
		preStart: 3,
		heatTime: 20,
		coolTime: 30,
		timer: null,
		start: null,
		hot: false,
		ready: false,
		mute: false,
		playedSound: false,
		now: null,
		timeSpent: 0.0
	}

	toggleTimer = () => {
		const {
			state: {
				timer,
			}
		} = this

		let args = {
			hot: false,
			ready: false,
			playedSound: false,
			timeSpent: 0,
		}

		if ( ! timer ) {
			this.setState({
				...args,
				buttonText: this.STOP_TEXT,
				timer: setInterval(this.tick, 100),
				start: (new Date()).getTime(),
			})
		} else {
			clearInterval(timer);
			this.setState({
				...args,
				buttonText: this.START_TEXT,
				timer: null,
				start: null,
			})
		}
	}

	tick = () => {
		const {
			state: {
				preStart,
				timer,
				start,
				heatTime,
				coolTime,
			} = {}
		} = this

		if ( ! timer || ! start ) {
			return;
		}

		const now = (new Date()).getTime()
		const timeSpent = ( (now - start) / 1000 ) - preStart;

		const hot = timeSpent >= heatTime
		const ready = timeSpent >= (heatTime + coolTime)

		this.setState({
			now,
			hot,
			ready,
			timeSpent: timeSpent.toFixed(1)
		})
	}

	parseVal = (val) => {
		return parseFloat('' === val ? '0' : val);
	}

	setHeatTime = (e) => {
		this.setState({heatTime: this.parseVal(e.target.value)})
	}

	setCoolTime = (e) => {
		this.setState({coolTime: this.parseVal(e.target.value)})
	}

	setPreStart = (e) => {
		this.setState({preStart: this.parseVal(e.target.value)})
	}

	componentDidUpdate() {
		const {
			state: {
				ready,
				mute,
				playedSound
			}
		} = this

		if ( ready && ! playedSound ) {
			if ( ! mute ) {
				this.AUDIO.play()
			}
			this.setState({playedSound: true})
		}
	}

	toggleMute = (e) => {
		const {
			state: {
				mute
			}
		} = this

		this.setState({ mute: ! mute })
	}

	render({}, {buttonText}) {
		const {
			state: {
				hot,
				ready,
				heatTime,
				coolTime,
				preStart,
				timeSpent,
				mute,
			}
		} = this

		return (
			<div className="app">
				<div className="timer">
					<div className="time">{timeSpent} seconds</div>
					<button onClick={this.toggleTimer}>{buttonText}</button>
					<div>
						<label for="prestarttime">Pre Start Time:</label>
						<input id="prestarttime" name="prestarttime" type="number" value={preStart} onInput={this.setPreStart} />
					</div>
					<div>
						<label for="heattime">Heat Time:</label>
						<input id="heattime" name="heattime" type="number" value={heatTime} onInput={this.setHeatTime} />
					</div>
					<div>
						<label for="cooltime">Cool Time:</label>
						<input id="cooltime" name="cooltime" type="number" value={coolTime} onInput={this.setCoolTime} />
					</div>
					<div>
						<label for="mute">Mute Sounds:</label>
						<input id="mute" name="mute" type="checkbox" checked={mute} onClick={this.toggleMute} />
					</div>
					<div className="bigText">
						{hot ? (<div className="hot">HOT!</div>) : ''}
						{ready ? (<div className="ready">READY!</div>) : ''}
					</div>
				</div>
				<div className="how-to">
					<h3>How to Dab</h3>
					<ol>
						<li>Set your times (pre-start, heat, and cool down).</li>
						<li>Press <strong>START</strong>.</li>
						<li>Use the pre-start time to setup your dab.</li>
						<li>Once the timer hits 0, use your <strong>torch</strong> to heat up your <strong>nail</strong>.</li>
						<li>Keep heating nail until <strong className="hot">HOT</strong> time is reached.</li>
						<li>Wait for cooldown time. You will see <strong className="ready">READY</strong> and hear a confirmation sound.</li>
						<li>Dab!</li>
						<li>Clean your nail with a <strong>q-tip</strong> after each use.</li>
						<li>Press <strong>STOP</strong> to reset.</li>
					</ol>
				</div>
			</div>
		)
	}
}

const mainElement = document.getElementById('app')
render(<App />, mainElement)
